import { useWeb3React } from '@web3-react/core'
import { Avatar, Button, Input, message, Modal } from 'antd'
import { Flex } from 'pancake-uikit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import {
  fetchALLLpStakingData,
  fetchALLLpStakingUserData,
  fetchLpStakingData,
  fetchLpStakingUserData,
  setLpRef,
  setLpStakingData,
  setLpStakingUserData,
} from 'state/lpStaking'
import { format } from 'date-fns'
import styled from 'styled-components'
import Dots from 'components/Loader/Dots'
import copy from 'copy-to-clipboard'
import useI18nText from 'hooks/useI18n'
import { utils } from 'ethers'
import {
  getLpPeipeiStaking1Address,
  getLpPeipeiStaking2Address,
  getLpStakingAddress,
  getNftMintAddress,
} from 'utils/addressHelpers'
import { useLockPosition, useLpApprove, useWithdraw, usetUsdtApprove } from './hook'

const LpStakingBody = styled.div`
  margin: 0px auto;
  margin-top: 20px;

  .list {
    width: 100%;
    height: 300px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #c56f6d;
    padding: 20px 24px;
    flex-direction: column;
    @media screen and (min-width: 360px) {
      padding: 20px 14px;
      margin-top: 20px;
    }
    @media screen and (min-width: 576px) {
      padding: 20px 24px;
      margin-top: 20px;
    }
    overflow: auto;
    .list-title {
      background-color: #fd0100;
      width: 100%;
      justify-content: space-between;
      padding: 10px 26px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 600;
      @media screen and (min-width: 360px) {
        padding: 10px 16px;
      }
      @media screen and (min-width: 576px) {
        padding: 10px 26px;
      }
      > div {
        flex: 1;
      }
      > div:nth-child(2) {
        text-align: center;
      }
      > div:nth-child(3) {
        text-align: right;
      }
    }
    .list-row {
      padding: 5px 26px;
      justify-content: space-between;
      font-size: 14px;
      @media screen and (min-width: 360px) {
        padding: 5px 16px;
      }
      @media screen and (min-width: 576px) {
        padding: 5px 26px;
      }
      > div {
        flex: 1;
      }
      > div:nth-child(2) {
        text-align: center;
      }
      > div:nth-child(3) {
        text-align: right;
      }
    }
  }
  .b-title {
    width: 100%;

    flex-direction: column;
    align-items: center;
    font-weight: 600;
    .b-2 {
      margin: 10px 0px;
    }
    .b-3 {
      border: 1px solid #000;
      border-radius: 10px;
      padding: 8px 46px;
      background: #fff;
      font-weight: 600;
      text-align: center;
      > p {
        font-size: 14px;
      }
    }
  }
  .user-lpStaking {
    @media screen and (min-width: 360px) {
      flex-direction: column;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }
  }
  .input {
    @media screen and (min-width: 360px) {
      flex-direction: column;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }
    button {
      @media screen and (min-width: 360px) {
        margin-top: 10px;
      }
      @media screen and (min-width: 576px) {
        margin-top: 0px;
      }
    }
  }
  @media screen and (min-width: 360px) {
    width: 100%;
  }
  @media screen and (min-width: 576px) {
    width: 1080px;
  }
  .body {
    @media screen and (min-width: 360px) {
      flex-direction: column;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }
    > div {
      width: '100%';
    }

    .rig {
      > div {
        @media screen and (min-width: 360px) {
          margin-top: 20px;
        }
        @media screen and (min-width: 576px) {
          margin-top: 0px;
        }
      }
    }
  }
  .win {
    > div {
      width: 100%;
      > div {
        padding: 5px 10px;
        border-radius: 8px;
        background: #d2abaa;
        border: 1px solid #c56f6d;
        margin-top: 10px;
      }
    }
    p {
      font-size: 12px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .data {
    h1 {
      font-size: 28px;
      span {
        margin-left: 10px;
        font-size: 24px;
      }
    }

    .data_1 {
      width: 100%;
      > div {
        padding: 5px 10px;
        border-radius: 8px;
        background: #d2abaa;
        border: 1px solid #c56f6d;
        margin-top: 10px;
        p {
          font-size: 12px;
        }
        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
  .div {
    border: 1px solid #c56f6d;
    background: #ffe0df;
    padding: 20px 24px;
    border-radius: 20px;
  }
  .ref {
    margin-top: 20px;
    border: 1px solid #c56f6d;
    background: #ffe0df;
    padding: 16px 24px;
    border-radius: 20px;
    h1,
    p,
    div {
      margin-bottom: 10px;
    }
    span {
      font-size: 14px;
    }
  }

  .nft_mint {
    border: 1px solid #c56f6d;
    background: #ffe0df;
    padding: 16px 24px;
    border-radius: 20px;

    > div {
      @media screen and (min-width: 360px) {
        flex-direction: column;
      }
      @media screen and (min-width: 576px) {
        flex-direction: row;
      }
    }
    .nft_img {
      @media screen and (min-width: 360px) {
        display: none;
      }
      @media screen and (min-width: 576px) {
        display: flex;
      }
      h1 {
        font-size: 28px;
      }
      div {
        margin-top: 20px;
      }
      img {
        width: 180px;
      }
    }

    .nft_input {
      img {
        width: 70px;
        z-index: 100;
      }
      h1 {
        font-size: 28px;
      }
      .mint-div {
        h1 {
          margin-left: -15px;
          padding: 5px 15px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          background: #e7b1b0;
        }
      }
    }

    .shu {
      margin: 20px 0px 10px 0px;
      font-size: 14px;
    }
  }

  .ant-btn-default:disabled {
    background: rgb(212, 212, 212, 0.8);
  }
`

const StakingLeft = styled.div`
  background: #ffe0df !important;
  padding: 20px 24px;
  border-radius: 16px;
  border: 1px solid #000000;
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    > span {
      color: red;
    }
  }
  .p1 {
    padding-top: 20px;
    .img {
      @media screen and (min-width: 360px) {
        display: none;
      }
      @media screen and (min-width: 576px) {
        display: flex;
      }

      width: 165px;
      height: 165px;
      position: relative;
      margin-right: 20px;
      > .imgP1 {
        height: 165px;
      }
      > .imgP2 {
        height: 68px;
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
    .input {
      flex: 1;
      > div {
        margin-bottom: 10px;
      }
    }
  }
`

const LockInput = styled(Input)`
  height: 48px;
  background: #d4d4d4;
  border-radius: 10px;
  border: none;
  padding: 4px 10px;
  border: 2px solid #000;

  input {
    background-color: #d4d4d4;
    &:focus,
    &:focus-within,
    &:hover {
      background-color: #d4d4d4;
    }

    &:focus {
      box-shadow: none;
    }
  }
  &:focus,
  &:focus-within,
  &:hover {
    background-color: #f3f3f3;
    border: 2px solid #000;
  }

  &:focus {
    box-shadow: none;
  }
`

const LockButton = styled(Button)`
  border: 1px solid #ffdad9;
  border-radius: 20px;
  margin-top: 20px;
  background-color: #c56f6d;
  &:hover {
    color: #d39c63 !important;
    border: 1px solid #e7b7ab !important;
  }
`

const ModalButton = styled(Button)`
  border: 2px solid #000;
  border-radius: 20px;
  margin-top: 20px;
  background-color: #ff9c00;
  &:hover {
    color: #000 !important;
    border: 2px solid #000 !important;
    background-color: #ff9c00 !important;
  }
`

const ModalButton1 = styled(Button)`
  border: 2px solid #000;
  border-radius: 20px;
  margin-top: 20px;
  background-color: #fd0100;
  &:hover {
    color: #000 !important;
    border: 2px solid #000 !important;
    background-color: #fd0100 !important;
  }
`

const CustomModal = styled(Modal)`
  .ant-modal {
    width: 320px !important;
  }
  .ant-modal-content {
    background: #ffe0df;
    border-radius: 30px;
    border: 2px solid #000;
  }
  .ant-modal-header {
    background: none;
  }

  .ant-modal-title {
    background: none;
  }
  .ant-modal-body {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
  }
  .ant-modal-footer {
    display: none;
    button:nth-of-type(2) {
      display: none;
    }
    .ant-btn {
      display: block;
      margin: 0px auto;
      /* background: linear-gradient(to right, #d19b35, #b0741e, #e6ba4c); */
      border-radius: 20px;
      height: 40px;
      padding: 0px 50px;
      color: #000;
      :hover {
        border: none;
        opacity: 0.5;
      }
    }
  }

  .flex-modal {
    flex: 1;
    height: 100%;
    flex-direction: column;
    .modal-title {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .modal-select {
      > div {
        flex: 1;
        /* justify-content: center; */
        align-items: center;
        > p {
          font-size: 13px;
          line-height: 10px;
        }
      }
    }
  }
`
const _parseFloat = (value, dec = 2) => {
  return value.match(`^\\d+(?:\\.\\d{0,${dec}})?`)
  // `^\\d+(?:\\.\\d{0,${dec}})?`
}

const Max = styled.div``
function getFirstTenCharacters(str) {
  if (str.length >= 10) {
    return str.substring(0, 10)
  }
  return '0.0'
}

const LpStaking = () => {
  const t = useI18nText()
  const { chainId, account } = useWeb3React()
  const dispatch = useAppDispatch()
  const [inputAmount, setInputAmount] = useState('')
  const [page, setPage] = useState(true)
  const [contractAddress, setContractAddress] = useState('')
  const store = useAppSelector((state) => state.lpStaking).data?.[contractAddress]
  console.log(contractAddress)
  const url = window.location.href

  /* eslint-disable */
  useEffect(() => {
    const refs = url.split('ref=')
    if (refs.length > 1) {
      const ref = refs[1]
      console.log(`utils.isAddress(ref): ${utils.isAddress(ref)}`)
      if (utils.isAddress(ref)) {
        dispatch(setLpRef(ref))
      }
    }
  }, [url])

  useEffect(() => {
    const fetchDataAndUserData = async () => {
      fetchALLLpStakingData(chainId).then((data) => dispatch(setLpStakingData(data)))
      if (account) {
        const userData = await fetchALLLpStakingUserData(chainId, account)
        dispatch(setLpStakingUserData(userData))
      }
    }
    // 执行一次初始逻辑
    fetchDataAndUserData()

    // 设置定时器，每隔一段时间执行一次逻辑
    const intervalId = setInterval(fetchDataAndUserData, 3000) // 每分钟执行一次

    // 在组件卸载时清除定时器
    return () => clearInterval(intervalId)
  }, [account, dispatch, chainId])

  console.log(store)

  const _amount = inputAmount?.slice(-1) === '.' ? inputAmount?.slice(0, -1) : inputAmount

  const depositError = useMemo(() => {
    if (store?.staking?.startTradeBlock === 0 || !store?.staking?.isClose) return t('Clone')
    else if (inputAmount === '') return ''
    else if (Number(_amount) === 0) return 'Input Amount 0'
    else if (Number(store?.user?.lpStaking?.balance) < Number(_amount)) return t('Insufficient Balance')
    else return ''
  }, [store, _amount, inputAmount])

  const withdrawError = useMemo(() => {
    if (store?.staking?.startTradeBlock === 0) return t('Clone')
    // else if (store?.user?.lpStaking?.amount === 0) return 'amount 0'
    else if (Number(store?.user?.lpStaking?.pendingReward) === 0) return t('Not Reward')
    else return ''
  }, [store, _amount, inputAmount])

  console.log(`ref: ${useAppSelector((state) => state.lpStaking)?.ref}`)
  const { isLoading: isLpApproveLoading, lpApprove } = useLpApprove(store?.staking?.uniswapPair, contractAddress)
  console.log(_amount)
  const { isLoading: isLockPositionLoading, lockPosition } = useLockPosition(
    contractAddress,
    _amount,
    useAppSelector((state) => state.lpStaking)?.ref
  )
  const { isLoading: isWithdrawLoading, withdraw } = useWithdraw(contractAddress)

  const [isModalOpen, setIsModalOpen] = useState(true)
  const [modalAddress, setModalAddress] = useState(1)
  return (
    <>
      <CustomModal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => {
          setIsModalOpen(false)
          if (modalAddress === 1) {
            setContractAddress(getLpPeipeiStaking1Address(chainId))
          } else {
            setContractAddress(getLpPeipeiStaking2Address(chainId))
          }
        }}
      >
        <Flex className="flex-modal">
          <div className="modal-title">请选择查看并质押的LP</div>
          <Flex className="modal-select">
            <Flex flexDirection="column">
              <div style={{ width: '48px', height: '48px', position: 'relative' }}>
                <img style={{ width: '48px' }} src="./modal1.png" alt="" />
                <img
                  style={{ width: '18px', top: '0px', right: '-3px', position: 'absolute' }}
                  src="./modal3.png"
                  alt=""
                />
              </div>
              <p style={{ marginTop: '10px' }}>BNB</p>
              <p>+</p>
              <p>CATME LP</p>
              <div
                style={{ cursor: 'pointer', width: '24px', height: '24px', marginTop: '5px', position: 'relative' }}
                onClick={() => {
                  setModalAddress(1)
                }}
              >
                <img style={{ width: '24px' }} src="./modal4.png" alt="" />
                {modalAddress === 1 && (
                  <img
                    style={{ width: '14px', top: '8px', right: '5px', position: 'absolute' }}
                    src="./modal5.png"
                    alt=""
                  />
                )}
              </div>
            </Flex>
            <Flex flexDirection="column">
              <div style={{ width: '48px', height: '48px', position: 'relative' }}>
                <img style={{ width: '48px' }} src="./modal2.png" alt="" />
                <img
                  style={{ width: '18px', top: '0px', right: '-3px', position: 'absolute' }}
                  src="./modal3.png"
                  alt=""
                />
              </div>
              <p style={{ marginTop: '10px' }}>BNB</p>
              <p>+</p>
              <p>PEIPEI LP</p>
              <div
                style={{ cursor: 'pointer', width: '24px', height: '24px', marginTop: '5px', position: 'relative' }}
                onClick={() => {
                  setModalAddress(2)
                }}
              >
                <img style={{ width: '24px' }} src="./modal4.png" alt="" />
                {modalAddress === 2 && (
                  <img
                    style={{ width: '14px', top: '8px', right: '5px', position: 'absolute' }}
                    src="./modal5.png"
                    alt=""
                  />
                )}
              </div>
            </Flex>
          </Flex>
          <Flex justifyContent="center">
            <ModalButton
              style={{ width: '70%' }}
              onClick={() => {
                setIsModalOpen(false)
                if (modalAddress === 1) {
                  setContractAddress(getLpPeipeiStaking1Address(chainId))
                } else {
                  setContractAddress(getLpPeipeiStaking2Address(chainId))
                }
              }}
            >
              确定
            </ModalButton>
          </Flex>
        </Flex>
      </CustomModal>
      {page ? (
        <LpStakingBody>
          <Flex className="body">
            <Flex flex="1" flexDirection="column">
              <StakingLeft>
                <div className="title">
                  双 LP 质押金本位 <span>3倍</span> 收益出局
                </div>

                <Flex className="p1">
                  <div className="img">
                    <img className="imgP1" src="./p1.png" alt="" />
                    <img className="imgP2" src="./p2.png" alt="" />
                  </div>
                  <div className="input">
                    <div>
                      <ModalButton
                        style={{
                          width: '100%',
                          fontWeight: 600,
                          background: '#ff008a',
                          borderRadius: '10px',
                          height: '48px',
                        }}
                        onClick={() => setIsModalOpen(true)}
                      >
                        {contractAddress?.toLowerCase() === getLpPeipeiStaking1Address(chainId)?.toLowerCase()
                          ? '质押 CATEM LP '
                          : contractAddress?.toLowerCase() === getLpPeipeiStaking2Address(chainId)?.toLowerCase()
                          ? '质押 PEIPEI LP'
                          : '请选择质押的 LP'}
                      </ModalButton>
                    </div>

                    <div>
                      <LockInput
                        placeholder="请输入质押 LP 的数量"
                        type="text"
                        value={inputAmount}
                        style={{ height: '48px', background: '#d4d4d4' }}
                        onChange={(e: any) => {
                          if (!Number.isNaN(e.target.value)) {
                            setInputAmount(e.target.value)
                          }
                        }}
                        suffix={
                          <Max
                            style={{ fontWeight: 600, color: '#fd0100', cursor: 'pointer' }}
                            onClick={() => {
                              const balance = store?.user?.lpStaking?.balance
                              if (!balance || Number(balance) < 0.000001) return
                              const lpBalance = utils.parseEther(balance)
                              setInputAmount(utils.formatEther(lpBalance.sub(utils.parseEther('0.000001'))))
                            }}
                          >
                            Max
                          </Max>
                        }
                      />
                    </div>
                    <div>
                      {store?.user?.lpStaking?.isApprove ? (
                        <ModalButton
                          style={{
                            height: '48px',
                            fontWeight: 600,
                            width: '100%',
                            borderRadius: '24px',
                            padding: '0px 50px',
                          }}
                          disabled={!!isLockPositionLoading || !!depositError}
                          onClick={lockPosition}
                        >
                          {isLockPositionLoading ? <Dots>{t('质押')}</Dots> : depositError || t('质押')}
                        </ModalButton>
                      ) : (
                        <ModalButton
                          style={{
                            height: '48px',
                            fontWeight: 600,
                            width: '100%',
                            borderRadius: '24px',
                            padding: '0px 50px',
                          }}
                          disabled={!!isLpApproveLoading}
                          onClick={lpApprove}
                        >
                          {isLpApproveLoading ? <Dots>{t('Approve')}</Dots> : t('Approve')}
                        </ModalButton>
                      )}
                    </div>
                  </div>
                </Flex>
              </StakingLeft>

              <Flex style={{ border: '1px solid #000', marginTop: '20px' }} className="win div" flexDirection="column">
                <Flex className="user-lpStaking">
                  <Flex flex="1" flexDirection="column">
                    <p>
                      待领取收益 <img src="./r1.png" style={{ width: '12px' }} alt="" />
                    </p>
                    <span>{_parseFloat(store?.user?.lpStaking?.pendingLp || '0')} PEIPEI</span>
                    <span style={{ fontSize: '10px', color: '#0000FF', fontWeight: 400 }}>
                      ≈ {_parseFloat(store?.user?.lpStaking?.pendingReward || '0', 5)} BNB
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column">
                    <p>
                      可领取收益 <img src="./r2.png" style={{ width: '12px' }} alt="" />
                    </p>
                    <span>{_parseFloat(store?.user?.lpStaking?.totalPendingLp || '0')} PEIPEI</span>
                    <span style={{ fontSize: '10px', color: '#0000FF', fontWeight: 400 }}>
                      ≈ {_parseFloat(store?.user?.lpStaking?.totalPendingReward || '0', 5)} BNB
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column">
                    <p>
                      已领取收益 <img src="./r3.png" style={{ width: '12px' }} alt="" />
                    </p>
                    <span>{_parseFloat(store?.user?.lpStaking?.claimLp || '0')} PEIPEI</span>
                    <span style={{ fontSize: '10px', color: '#0000FF', fontWeight: 400 }}>
                      ≈ {_parseFloat(store?.user?.lpStaking?.claimReward || '0', 5)} BNB
                    </span>
                  </Flex>
                </Flex>
                <Flex>
                  <ModalButton1
                    style={{ width: '100%', borderRadius: '24px', height: '48px' }}
                    disabled={!!isWithdrawLoading || !!withdrawError}
                    onClick={withdraw}
                  >
                    {isWithdrawLoading ? <Dots>{t('领取')}</Dots> : withdrawError || t('领取')}
                  </ModalButton1>
                </Flex>
              </Flex>
            </Flex>
            <p style={{ width: '20px' }} />
            <Flex flex="1" className="rig" flexDirection="column">
              <Flex className="data div" flexDirection="column">
                <Flex className="data_1">
                  <Flex flex="1" flexDirection="column" style={{ background: '#ffffff' }}>
                    <p>我的 LP 价值</p>
                    <span>{_parseFloat(store?.user?.lpStaking?.balance || '0', 4)} LP</span>
                    <span style={{ fontSize: '10px', color: '#ffa800', fontWeight: 400 }}>
                      ≈ {_parseFloat(store?.user?.lpStaking?.balanceBnb || '0', 5)} BNB
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column" style={{ background: '#ffffff' }}>
                    {' '}
                    <p>我已质押 LP 价值</p>
                    <span>{_parseFloat(store?.user?.lpStaking?.amount || '0', 4)} LP</span>
                    <span style={{ fontSize: '10px', color: '#ffa800', fontWeight: 400 }}>
                      ≈ {_parseFloat(store?.user?.lpStaking?.bnbAmount || '0', 5)} BNB
                    </span>
                  </Flex>
                </Flex>
                <Flex className="data_1">
                  <Flex flex="1" flexDirection="column" style={{ background: '#ffe3ca' }}>
                    <p>全网总质押 LP 价值</p>
                    <span>{_parseFloat(store?.staking?.TotalLockLpAmount || '0', 4)} LP</span>
                    <span style={{ fontSize: '10px', color: '#0000FF', fontWeight: 400 }}>
                      ≈ {_parseFloat(store?.staking?.TotalLockLpBnbAmount || '0', 5)} BNB
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column" style={{ background: '#ffe3ca' }}>
                    <p>全网总收益 PEIPEI 价值</p>
                    <span>{_parseFloat(store?.staking?.TotalClaimLp || '0', 2)} PEIPEI</span>
                    <span style={{ fontSize: '10px', color: '#0000FF', fontWeight: 400 }}>
                      ≈ {_parseFloat(store?.staking?.TotalClaimReward || '0', 5)} BNB
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex className="ref" flexDirection="column" style={{ border: '1px solid #000', marginTop: '20px' }}>
                <p style={{ fontSize: '14px' }}>我的邀请链接</p>
                <Flex style={{ width: '100%' }}>
                  <LockInput
                    type="text"
                    value={`https://dapp.peipeicoinbsc.xyz/#/?ref=${account}`}
                    style={{ border: '1px solid #c56f6d', height: '38px', background: '#d4d4d4' }}
                  />
                  <ModalButton
                    style={{ margin: ' 0px', height: '38px', padding: '0px 20px' }}
                    onClick={() => {
                      copy(`https://dapp.peipeicoinbsc.xyz/#/?ref=${account}`)
                      message.success('copy success')
                    }}
                  >
                    复制
                  </ModalButton>
                </Flex>
                <span style={{ fontSize: '12px' }}>说明: 直推加速释放 10%</span>
              </Flex>

              <Flex>
                <ModalButton style={{ width: '100%', height: '48px' }} onClick={() => setPage(false)}>
                  查看奖励明细
                </ModalButton>
              </Flex>
            </Flex>
          </Flex>
        </LpStakingBody>
      ) : (
        <LpStakingBody>
          <Flex className="div" style={{ border: '2px solid #000' }}>
            <Flex className="b-title">
              <div className="b-1">奖励总计</div>
              <div className="b-2">{Number(store?.staking?.TotalClaimLp || 0).toFixed(5)}</div>
              <div className="b-3">
                <p> 加速 </p>
                <span>{(store?.user?.lpStaking?.hastenBnbAmount && store?.user?.lpStaking?.hastenBnbAmount) || 0}</span>
              </div>
            </Flex>
          </Flex>

          <Flex className="list" style={{ border: '2px solid #000' }}>
            <Flex className="list-title">
              <div>时间</div>
              <div>数量</div>
              <div>概览</div>
            </Flex>
            {(store?.user?.lpStaking?.logs || []).map((log) => (
              <Flex className="list-row">
                <div>{format(Number(log.time) * 1000 || 0, 'MM-dd HH:mm')}</div>
                <div>{_parseFloat(log.amount || '0', 5)}</div>
                <div>{log.types}</div>
              </Flex>
            ))}
          </Flex>
          <Flex>
            <ModalButton style={{ width: '100%', height: '48px' }} onClick={() => setPage(true)}>
              返回
            </ModalButton>
          </Flex>
        </LpStakingBody>
      )}
    </>
  )
}

export default LpStaking
