import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
import {
  getBurnCatAddress,
  getCatStakingAddress,
  getLpStakingAddress,
  getMulticallAddress,
  getNftMintAddress,
  getStakingAddress,
} from 'utils/addressHelpers'

// ABI
import Multicall_abi from 'config/abi/Multicall.json'
import Erc20_abi from 'config/abi/Erc20.json'
import Staking_abi from 'config/abi/Staking.json'
import CatStaking_abi from 'config/abi/CatStaking.json'

import BurnCat_abi from 'config/abi/BurnCat.json'
import LpStaking_abi from 'config/abi/LpStaking.json'
import NFTMint_abi from 'config/abi/NFTMint.json'
import LpPeipeiStaking_abi from 'config/abi/LpPeipeiStaking.json'
import { BurnCat, CatStaking, Erc20, LpStaking, Multicall, NFTMint, Staking } from 'config/abi/types/index'

// Types

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer || simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getERC20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  if(!address){
    return undefined
  }
  return getContract(Erc20_abi, address, signer) as Erc20
}

export const getMulticallContract = (chainId?: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(Multicall_abi, getMulticallAddress(chainId), signer) as Multicall
}

export const getStakingContract = (chainId?: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(Staking_abi, getStakingAddress(chainId), signer) as Staking
}

export const getCatStakingContract = (chainId?: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = getCatStakingAddress(chainId)
  if (!address) {
    return undefined
  }
  return getContract(CatStaking_abi, getCatStakingAddress(chainId), signer) as CatStaking
}

export const getBurnCatContract = (chainId?: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = getBurnCatAddress(chainId)
  if (!address) {
    return undefined
  }
  return getContract(BurnCat_abi, getBurnCatAddress(chainId), signer) as BurnCat
}

export const getLpStakingContract = (chainId?: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = getLpStakingAddress(chainId)
  if (!address) {
    return undefined
  }
  return getContract(LpStaking_abi, getLpStakingAddress(chainId), signer) as LpStaking
}

export const getNftMintContract = (chainId?: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = getNftMintAddress(chainId)
  if (!address) {
    return undefined
  }
  return getContract(NFTMint_abi, getNftMintAddress(chainId), signer) as NFTMint
}

export const getLpPeipeiStakingContract = (
  chainId?: number,
  address?: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  if (!address) {
    return undefined
  }
  return getContract(LpPeipeiStaking_abi, address, signer) as any
}

export default {}
