import { spawn } from 'child_process'
import { MenuEntry } from 'pancake-uikit'

/* eslint-disable */
const config: MenuEntry[] = [
  {
    label: 'CATME DAPP',
    icon: 'HomeIcon',
    href: 'https://catme.xyz/#/',
  },
]

export default config
